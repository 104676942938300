<template>
  <div>ups Help</div>
</template>

<script>
import { Page } from 'global-components';
export default {
  name: 'help-page',
  extends: Page
};
</script>
